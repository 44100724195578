/*!
 * Tabler (v1.0.0-alpha.5)
 * Copyright 2018-2020 The Tabler Authors
 * Copyright 2018-2020 codecalm
 * Licensed under MIT (https://github.com/tabler/tabler/blob/master/LICENSE)
 */

@import "config";
@import "ui/buttons-extra";

